<template>
    <h6 class="text-start" style="color: white; font-weight: 600">
        {{ title }}
    </h6>
</template>

<script>

export default {
    name: "PageTitle",
    props: {
        title: {
            type: String,
            default: 'Page Title'
        }
    }
}
</script>