<template>
  <div v-show="isShowModal">
    <div class="sds-modal h-100 justify-content-center">
      <div class="sds-modal-body mx-auto">
        <div class="modal-header pt-0">
          <h2 class="modal-title">{{ title }}</h2>
          <button type="button" class="close" v-on:click="closeModal">
            <span aria-hidden="true" style="color: white;">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ body }} ?</p>
        </div>
        <div class="col-lg-12 col-sm-12 text-center mt-2">
          <button
            style="width: 100px"
            v-on:click="onClick()"
            id="delete-button"
            class="fill-btn px-4 ml-3"
          >
            Yes
          </button>
          <button
            style="width: 100px"
            type="button"
            v-on:click="closeModal()"
            class="delete-btn px-4 ml-3"
            id="cancel-button"
          >
            No
          </button>
        </div>
      </div>
    </div>
    <div class="sds-modal-background"></div>
  </div>
</template>
<script>
export default {
  name: "DeleteModal",
  data() {
    return {
      isShowModal: false,
      title: "Alert",
      body: "Are you sure you want to delete",
      obj: {},
    };
  },
  mounted() {},
  methods: {
    showModal(title, body, obj) {
      this.title = title;
      this.body = body;
      this.obj = obj;
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
    onClick() {
      this.$emit("remove", this.obj);
    },
    deleteRecord(url) {
      this.$api
        .deleteAPI({
          _action: url,
          _buttonId: "delete-button",
        })
        .then((res) => {
          if (res) {
            this.$emit("get-list");
            this.closeModal();
            this.$notify({
               title: "Success",
                        type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>