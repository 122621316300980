<template>
    <div v-if="isShowModal">
        <div class="sds-modal h-100 justify-content-center">
            <div class="sds-modal-body mx-auto" :style="'width:' + width + '%'">
                <slot></slot>
            </div>
        </div>
        <div class="sds-modal-background"></div>
    </div>
</template>
<script>
export default {
    props: {
        width: {
            type: Number,
            default: 30
        },
    },
    name: "TextModal",
    data() {
        return {
            isShowModal: false,
        }
    },
    mounted() { },
    methods: {
        showModal() {
            this.isShowModal = true
        },
        closeModal() {
            this.isShowModal = false
        }
    }
}
</script>