<template>
  <div v-show="isShowModal">
    <div class="sds-modal h-100 justify-content-center">
      <div class="sds-modal-body-new mx-auto">
        <div class="modal-header pt-0">
          <!-- <p class="modal-title">TRADE BOOK</p> -->
          <button type="button" class="close" v-on:click="closeModal()">
            <span aria-hidden="true" style="color: white;">&times;</span>
          </button>
        </div>
        <div class="container form-signin3">
          <div class="row">
            <div
              class="col-md-12"
              style="padding-left: 50px; padding-right: 50px">
              <div class="col-lg-12 row ml-1">
                <p
                  style="
                    color: black;
                    text-align: justify;
                    font-weight: bold;
                    font-size: 12px;
                    margin: 0px !important;
                    margin-top: 7px !important;
                  ">
                  Wrong Trade
                </p>
                <div class="col-lg-2">
                  <Field
                    type="text"
                    class="form-control"
                    name="ledger_balance"
                    placeholder="SEARCH"
                    v-model="keyword"
                    v-on:keyup="getList()" />
                </div>
              </div>
            </div>
          </div>

          <div
            class="row"
            style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
            <div
              class="col-md-12 bg-light sticky-table-header"
              style="height: 400px">
              <table
                id="table"
                style="margin-top: 16px"
                data-toggle="table"
                data-search="true"
                data-filter-control="true"
                data-toolbar="#toolbar"
                class="table table-sm text-center text-white table-bordered product-list table-hover">
                <thead>
                  <tr>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      D
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      Sr No.
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      Trade No
                    </th>

                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      O/T
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      Type
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      Trade Date
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      Client
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      Athority
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      Script
                    </th>

                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      Lot
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      Qty
                    </th>

                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      Rate
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      Net Rate
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      User
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      IP
                    </th>
                    <!-- <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      O-Time
                    </th> -->
                    <th class="head" id="id-name">T-T</th>
                  </tr>
                </thead>
                <tbody v-show="!loader">
                  <tr v-for="(data, index) in list" :key="index">
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      <i
                        v-if="data.device == 'WEB'"
                        class="fa fa-globe"
                        aria-hidden="true"></i>
                      <i
                        v-if="data.device == 'MOBILE'"
                        class="fa fa-mobile"
                        aria-hidden="true"></i>
                      <i
                        class="fa fa-user"
                        v-if="data.device == 'ADMIN'"
                        aria-hidden="true"></i>
                      <i
                        class="fa fa-user"
                        v-if="!data.device"
                        aria-hidden="true"></i>
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ index + 1 }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.id }}
                    </td>

                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.order_type }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                      :style="
                        data.order_action == 'BUY'
                          ? 'color: blue;'
                          : 'color: red;'
                      ">
                      {{
                        data.order_type == "MARKET"
                          ? data.order_action
                          : data.order_type == "INTRADAY"
                          ? "Intraday Close"
                          : data.order_action + " " + data.order_type
                      }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{
                        data.created_at
                          ? $helperService.getDate(data.created_at)
                          : ""
                      }}
                    </td>
                    <td
                      :title="data.user ? data.user.name : ''"
                      style="text-align: left; cursor: pointer"
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{
                        data.user
                          ? $helperService.getUpperCase(
                              data.user?.code + "-" + data.user?.name
                            )
                          : ""
                      }}
                    </td>
                    <td
                      :title="data.athority ? data.athority.name : ''"
                      style="text-align: left"
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{
                        data.athority
                          ? $helperService.getUpperCase(
                              data.athority?.name + "-" + data.user?.code
                            )
                          : ""
                      }}
                    </td>
                    <td
                      style="text-align: left"
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.trading_symbol ? data.trading_symbol : "" }}
                    </td>

                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.lot }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.quantity }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.price }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.net_price?.toFixed(2) }}
                    </td>
                    <td
                      style="text-align: left"
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{
                        data.user
                          ? $helperService.getUpperCase(data.user?.name)
                          : ""
                      }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.ip }}
                    </td>

                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{
                        data.trade_date
                          ? $helperService.getDateTime(data.trade_date)
                          : ""
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sds-modal-background"></div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
// import moment from "moment";
import { Field } from "vee-validate";
// import Datepicker from "vue3-datepicker";
export default {
  name: "TextModal",
  components: {
    ErrorComponent,
    Field,
    // Datepicker,
  },
  data() {
    return {
      id: "",
      isShowModal: false,
      loader: true,
      list: [],
      keyword: "",
      fromDate: "",
      toDate: "",
      type: "",
    };
  },
  mounted() {},
  methods: {
    showModal(data) {
      this.list = [];
      this.isShowModal = true;
      if (data) {
        this.getHighLow(data);
      }
    },
    getHighLow(data) {
      this.$api
        .getAPI({
          _action: "/market-data",
          _isExternal: true,
        })
        .then((res) => {
          if (data && data.length > 0) {
            for (var i in data) {
              for (var j in res) {
                if (data[i].instrument_token == res[j].t) {
                  if (data[i].price < res[j].l) {
                    this.list.push(data[i]);
                  }
                  if (data[i].price > res[j].h) {
                    this.list.push(data[i]);
                  }
                }
              }
            }
            this.loader = false;
            if (this.list && this.list.length > 0) {
              var obj = {};
              obj.list = this.list;
              this.$refs.errorComponent.setData(obj, "No Data available");
            }
          } else {
            var object = {};
            object.list = this.list;
            this.$refs.errorComponent.setData(object, "No Data available");
          }
        })
        .catch(() => {});
    },
    closeModal() {
      this.isShowModal = false;
    },
    // getList() {
    //   this.$refs.errorComponent.updateFormLoader(true);
    //   this.list = [];
    //   var searchObj = {};
    //   if (this.fromDate) {
    //     searchObj.from_date = moment(this.fromDate).format("yyyy-MM-DD");
    //   }

    //   if (this.keyword) {
    //     searchObj.keyword = this.keyword;
    //   }
    //   if (this.toDate) {
    //     searchObj.to_date = moment(this.toDate).format("yyyy-MM-DD");
    //   }

    //   if (this.id) {
    //     searchObj.user_id = this.id;
    //   }

    //   // searchObj.day = "TODAY";
    //   searchObj.trade_status = "EXECUTED";
    //   searchObj.sort_by = "trade_date";
    //   searchObj.order_by = "desc";

    //   this.$api
    //     .getAPI({
    //       _action: "/trade-list",
    //       _body: searchObj,
    //     })
    //     .then((res) => {
    //       this.loader = false;
    //       this.list = res;
    //       res.list = this.list;
    //       if (this.$refs.errorComponent) {
    //         this.$refs.errorComponent.setData(res, "No Data available");
    //         this.$refs.errorComponent.updateFormLoader(false);
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //       if (this.$refs.errorComponent) {
    //         this.$refs.errorComponent.setData(0, undefined, "");
    //         this.$refs.errorComponent.updateFormLoader(false);
    //       }
    //     });
    // }
  },
};
</script>
