<template>
  <div
    class="p0 bg-dark"
    style="top: 0px; position: fixed; width: 100%; height: 55px; padding: 10px">
    <div class="row upper-form">
      <div class="col">
        <nav
          class="navbar navbar-expand-lg navbar-light dashboard-menu d-none d-xl-block">
          <div class="navbar-collapse mt-2" id="navbarNav">
            <ul
              class="navbar-nav"
              style="width: 70%; margin-top: -15px; display: block ruby">
              <li style="float: left">
                <img src="/img/logo.png" style="height: 54px;object-fit: cover;"/>
              </li>
              <li
                style="float: left"
                class="nav-item active 618135"
                v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'">
                <a
                  style="margin-top: 10px; padding: 8px 4px 0px"
                  v-on:click="$router.push('/dashboard')"
                  class="nav-link"
                  >HOME
                </a>
              </li>
              <li
                style="float: left"
                class="nav-item"
                v-if="
                  role != 'ADMIN' &&
                  role != 'SUPER_ADMIN' &&
                  this.$store.getters.getAccess?.is_market_watch
                ">
                <a
                  style="margin-top: 10px; padding: 8px 4px 0px"
                  v-on:click="$router.push('/market-watch')"
                  class="nav-link"
                  >MARKET WATCH
                </a>
              </li>
              <li
                style="float: left"
                class="nav-item"
                v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'">
                <a
                  style="margin-top: 10px; padding: 8px 4px 0px"
                  v-on:click="$router.push('/orders')"
                  class="nav-link"
                  >ORDER BOOK
                </a>
              </li>
              <li
                style="float: left"
                class="nav-item"
                v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'">
                <a
                  style="margin-top: 10px; padding: 8px 4px 0px"
                  v-on:click="$router.push('/trades')"
                  class="nav-link"
                  >TRADE BOOK
                </a>
              </li>
              <li
                style="float: left"
                class="nav-item"
                v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'">
                <a
                  style="margin-top: 10px; padding: 8px 4px 0px"
                  v-on:click="$router.push('/positions')"
                  class="nav-link"
                  >POSITION BOOK
                </a>
              </li>
              <li style="float: left" class="nav-item dropdown">
                <a
                  style="margin-top: 10px; padding: 8px 4px 0px"
                  class="nav-link dropbtn">
                  REPORTS
                </a>
                <div class="dropdown-content">
                  <a
                    v-if="
                      (role != 'USER' &&
                        this.$store.getters.getAccess?.is_summaryReport) ||
                      role == 'ADMIN' ||
                      role == 'SUPER_ADMIN'
                    "
                    class="cursor-pointer"
                    v-on:click="$router.push('/trade-summary-report')"
                    >Summary Report</a
                  >
                  <a
                    v-if="
                      (role != 'USER' &&
                        this.$store.getters.getAccess?.is_branchReport) ||
                      role == 'ADMIN' ||
                      role == 'SUPER_ADMIN'
                    "
                    class="cursor-pointer"
                    v-on:click="$router.push('/branch-summary-report')"
                    >Branch Report</a
                  >
                  <a
                    v-if="
                      this.$store.getters.getAccess?.is_ledgerReport ||
                      role == 'ADMIN' ||
                      role == 'SUPER_ADMIN'
                    "
                    class="cursor-pointer"
                    v-on:click="$router.push('/ledger-report')"
                    >Ledger Report</a
                  >
                  <a
                    v-if="
                      (role == 'SUPER_MASTER' &&
                        this.$store.getters.getAccess?.is_settlement) ||
                      role == 'ADMIN' ||
                      role == 'SUPER_ADMIN'
                    "
                    class="cursor-pointer"
                    v-on:click="$router.push('/settlement')"
                    >Settlement</a
                  >
                  <a
                    v-if="role == 'ADMIN' || role == 'SUPER_MASTER'"
                    class="cursor-pointer"
                    v-on:click="$router.push('/turn-over-report')"
                    >Turnover Report</a
                  >
                  <a
                    class="cursor-pointer"
                    v-on:click="$router.push('/edit-account-log')"
                    v-if="this.$store.getters.getAccess?.is_accountEditLog"
                    >Accounts Edit Log</a
                  >
                  <a
                    class="cursor-pointer"
                    v-on:click="$router.push('/edit-delete-log')"
                    v-if="this.$store.getters.getAccess?.is_orderEditLog"
                    >Order/Trade Edit/Delete Log</a
                  >
                  <a
                    class="cursor-pointer"
                    v-on:click="$router.push('/rejection-log')"
                    v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'"
                    >Order/Trade Rejection Log</a
                  >
                </div>
              </li>
              <li
                style="float: left"
                class="nav-item dropdown"
                v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'">
                <a
                  v-on:click="$router.push('/trade-export')"
                  style="margin-top: 10px; padding: 8px 4px 0px"
                  class="nav-link dropbtn">
                  EXPORT TRADES
                </a>
              </li>

              <li
                style="float: left"
                class="nav-item dropdown"
                v-if="role != 'USER'">
                <a
                  style="margin-top: 10px; padding: 8px 4px 0px"
                  class="nav-link dropbtn">
                  MASTER
                </a>
                <div
                  class="dropdown-content"
                  v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'">
                  <a
                    class="cursor-pointer"
                    v-on:click="$router.push('/super-masters')"
                    >Super Master</a
                  >
                  <a
                    v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'"
                    class="cursor-pointer"
                    v-on:click="$router.push('/all-brokerages')"
                    >Brokerage</a
                  >
                  <a
                    class="cursor-pointer"
                    v-on:click="$router.push('/new-account')"
                    >New Account</a
                  >
                </div>
                <div class="dropdown-content" v-if="role == 'SUPER_MASTER'">
                  <a
                    v-if="this.$store.getters.getAccess?.is_manager"
                    class="cursor-pointer"
                    v-on:click="$router.push('/manager')"
                    >Manager</a
                  >
                  <a
                    v-if="this.$store.getters.getAccess?.is_master"
                    class="cursor-pointer"
                    v-on:click="$router.push('/masters')"
                    >Master</a
                  >
                  <a
                    v-if="this.$store.getters.getAccess?.is_broker"
                    class="cursor-pointer"
                    v-on:click="$router.push('/broker')"
                    >Broker</a
                  >
                  <a
                    v-if="this.$store.getters.getAccess?.is_customer"
                    class="cursor-pointer"
                    v-on:click="$router.push('/users')"
                    >Customer</a
                  >
                  <a
                    v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'"
                    class="cursor-pointer"
                    v-on:click="$router.push('/all-brokerages')"
                    >Brokerage</a
                  >
                  <a
                    v-if="this.$store.getters.getAccess?.is_addAccount"
                    class="cursor-pointer"
                    v-on:click="$router.push('/new-account')"
                    >New Account</a
                  >
                </div>
                <div class="dropdown-content" v-if="role == 'MANAGER'">
                  <a
                    v-if="this.$store.getters.getAccess?.is_master"
                    class="cursor-pointer"
                    v-on:click="$router.push('/masters')"
                    >Master</a
                  >
                  <a
                    v-if="this.$store.getters.getAccess?.is_broker"
                    class="cursor-pointer"
                    v-on:click="$router.push('/broker')"
                    >Broker</a
                  >
                  <a
                    v-if="this.$store.getters.getAccess?.is_customer"
                    class="cursor-pointer"
                    v-on:click="$router.push('/users')"
                    >Customer</a
                  >
                  <a
                    class="cursor-pointer"
                    v-on:click="$router.push('/all-brokerages')"
                    >Brokerage</a
                  >
                  <a
                    v-if="this.$store.getters.getAccess?.is_addAccount"
                    class="cursor-pointer"
                    v-on:click="$router.push('/new-account')"
                    >New Account</a
                  >
                </div>
                <div class="dropdown-content" v-if="role == 'MASTER'">
                  <a
                    v-if="this.$store.getters.getAccess?.is_broker"
                    class="cursor-pointer"
                    v-on:click="$router.push('/broker')"
                    >Broker</a
                  >
                  <a
                    v-if="this.$store.getters.getAccess?.is_customer"
                    class="cursor-pointer"
                    v-on:click="$router.push('/users')"
                    >Customer</a
                  >
                  <a
                    class="cursor-pointer"
                    v-on:click="$router.push('/all-brokerages')"
                    >Brokerage</a
                  >
                  <a
                    v-if="this.$store.getters.getAccess?.is_addAccount"
                    class="cursor-pointer"
                    v-on:click="$router.push('/new-account')"
                    >New Account</a
                  >
                </div>
                <div class="dropdown-content" v-if="role == 'BROKER'">
                  <a
                    v-if="this.$store.getters.getAccess?.is_customer"
                    class="cursor-pointer"
                    v-on:click="$router.push('/users')"
                    >Customer</a
                  >
                </div>
              </li>
              <li
                style="float: left"
                class="nav-item dropdown"
                v-if="role != 'USER' && role != 'BROKER'">
                <a
                  style="margin-top: 10px; padding: 8px 4px 0px"
                  class="nav-link dropbtn">
                  SETTINGS
                </a>
                <div class="dropdown-content">
                  <a
                    v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'"
                    class="cursor-pointer"
                    v-on:click="$router.push('/setting')"
                    >Time Setting</a
                  >
                  <a
                    v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'"
                    class="cursor-pointer"
                    v-on:click="$router.push('/theme-setting')"
                    >Theme Setting</a
                  >
                  <a
                    v-if="
                      role == 'ADMIN' ||
                      role == 'SUPER_MASTER' ||
                      role == 'SUPER_ADMIN'
                    "
                    class="cursor-pointer"
                    v-on:click="$router.push('/master-notifications')"
                    >Notification</a
                  >
                  <a
                    v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'"
                    class="cursor-pointer"
                    v-on:click="redirectTo('/manage-master-scripts')"
                    >Manage Master Scripts</a
                  >
                  <a
                    v-if="
                      (role == 'SUPER_MASTER' || role == 'MANAGER') &&
                      this.$store.getters.getAccess?.is_quantitySetting
                    "
                    class="cursor-pointer"
                    v-on:click="$router.push('/master-quantity-setting')"
                    >Master Quantity Setting</a
                  >
                  <a
                    v-if="
                      (role == 'SUPER_MASTER' || role == 'MANAGER') &&
                      this.$store.getters.getAccess?.is_orderLimit
                    "
                    class="cursor-pointer"
                    v-on:click="$router.push('/master-order-limit')"
                    >Master Order Limit</a
                  >
                  <a
                    v-if="
                      (role == 'SUPER_MASTER' || role == 'MANAGER') &&
                      this.$store.getters.getAccess?.is_segmentSetting
                    "
                    class="cursor-pointer"
                    v-on:click="redirectTo('/master-segment-setting')"
                    >Master Segment Setting</a
                  >
                  <a
                    v-if="
                      (role == 'SUPER_MASTER' ||
                        role == 'MASTER' ||
                        role == 'MANAGER') &&
                      this.$store.getters.getAccess?.is_quantitySetting
                    "
                    class="cursor-pointer"
                    v-on:click="$router.push('/quantity-setting')"
                    >Client Quantity Setting</a
                  >
                  <a
                    v-if="
                      (role == 'SUPER_MASTER' ||
                        role == 'MASTER' ||
                        role == 'MANAGER') &&
                      this.$store.getters.getAccess?.is_segmentSetting
                    "
                    class="cursor-pointer"
                    v-on:click="$router.push('/client-segment-setting')"
                    >Client Segment Setting</a
                  >
                  <a
                    v-if="
                      (role == 'SUPER_MASTER' ||
                        role == 'MASTER' ||
                        role == 'MANAGER') &&
                      this.$store.getters.getAccess?.is_orderLimit
                    "
                    class="cursor-pointer"
                    v-on:click="$router.push('/client-order-limit')"
                    >Client Order Limit</a
                  >
                  <a
                    v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'"
                    class="cursor-pointer"
                    v-on:click="$router.push('/manage-scripts')"
                    >Manage Scripts</a
                  >

                  <a
                    v-if="
                      (role == 'SUPER_MASTER' ||
                        role == 'MASTER' ||
                        role == 'MANAGER') &&
                      this.$store.getters.getAccess?.is_blockScript
                    "
                    class="cursor-pointer"
                    v-on:click="$router.push('/block-script')"
                    >Block Client Script</a
                  >
                  <a
                    v-if="
                      (role == 'SUPER_MASTER' || role == 'MANAGER') &&
                      this.$store.getters.getAccess?.is_blockScript
                    "
                    class="cursor-pointer"
                    v-on:click="$router.push('/block-master-script')"
                    >Block Master Script</a
                  >
                  <li v-if="this.$store.getters.getAccess?.is_blockIp">
                    <a
                      class="cursor-pointer"
                      v-on:click="redirectTo('/block-ip')"
                      >Black List IP</a
                    >
                  </li>
                  <a
                    v-if="this.$store.getters.getAccess?.is_transferSetting"
                    class="cursor-pointer"
                    v-on:click="$router.push('/transfer-setting')"
                    >Client Transfer Setting</a
                  >
                  <a
                    v-if="
                      (role == 'SUPER_MASTER' || role == 'MANAGER') &&
                      this.$store.getters.getAccess?.is_transferSetting
                    "
                    class="cursor-pointer"
                    v-on:click="redirectTo('/transfer-master-setting')"
                    >Master Transfer Setting</a
                  >
                </div>
              </li>
              <!-- <li
                style="float: left"
                class="nav-item"
                v-if="role === 'SUPER_ADMIN' || role === 'ADMIN'">
                <a
                  style="margin-top: 10px; padding: 8px 4px 0px"
                  v-on:click="$router.push('/tenents')"
                  class="nav-link"
                  >TENANTS
                </a>
              </li> -->
            </ul>
            <div class="marquee header-marquee">
            <p> {{ message }}</p>
          </div>
          </div>
        </nav>
        <button
          class="navbar-toggler d-xl-none"
          type="button"
          v-on:click="toggleMenu()"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            style="fill: rgba(0, 0, 0, 1);">
            <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
          </svg>
        </button>

        <div
          class="sidebar-wrapper d-xl-none"
          style="overflow-y: scroll"
          id="sidebar-div">
          <ul class="metismenu" id="menu">
            <li
              class="nav-item active 618135"
              v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'">
              <a v-on:click="redirectTo('/dashboard')" class="nav-link"
                >HOME
              </a>
            </li>
            <li
              class="nav-item"
              v-if="role != 'ADMIN' && role != 'SUPER_ADMIN' &&
                  this.$store.getters.getAccess?.is_market_watch">
              <a v-on:click="redirectTo('/market-watch')" class="nav-link"
                >MARKET WATCH
              </a>
            </li>
            <li
              class="nav-item"
              v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'">
              <a v-on:click="redirectTo('/orders')" class="nav-link"
                >ORDER BOOK
              </a>
            </li>
            <li
              class="nav-item"
              v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'">
              <a v-on:click="redirectTo('/trades')" class="nav-link"
                >TRADE BOOK
              </a>
            </li>
            <li
              class="nav-item"
              v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'">
              <a v-on:click="redirectTo('/positions')" class="nav-link"
                >POSITION BOOK
              </a>
            </li>

            <li :class="view.is_system_report == true ? 'mm-active' : ''">
              <a
                v-on:click="showDown('is_system_report')"
                class="has-arrow"
                style="cursor: pointer">
                <div>REPORTS</div>
              </a>
              <ul
                :class="
                  view.is_system_report == true
                    ? 'mm-collapse mm-show'
                    : 'mm-collapse'
                "
                style="
                  background: transparent !important;
                  margin-left: 35px;
                  border: 0px !important;
                ">
                <li>
                  <a
                    class="cursor-pointer"
                    v-on:click="$router.push('/trade-summary-report')"
                    >Summary Report</a
                  >
                </li>
                <li>
                  <a
                    class="cursor-pointer"
                    v-on:click="$router.push('/branch-summary-report')"
                    >Branch Report</a
                  >
                </li>
                <li>
                  <a
                    class="cursor-pointer"
                    v-on:click="redirectTo('/ledger-report')"
                    >Ledger Report</a
                  >
                </li>
                <li>
                  <a
                    v-if="
                      role == 'ADMIN' ||
                      role == 'SUPER_ADMIN' ||
                      role == 'SUPER_MASTER'
                    "
                    class="cursor-pointer"
                    v-on:click="redirectTo('/settlement')"
                    >Settlement</a
                  >
                </li>
                <li>
                  <a
                    v-if="role == 'ADMIN' || role == 'SUPER_MASTER'"
                    class="cursor-pointer"
                    v-on:click="$router.push('/turn-over-report')"
                    >Turnover Report</a
                  >
                </li>
                <li>
                  <a
                    class="cursor-pointer"
                    v-on:click="redirectTo('/edit-account-log')"
                    v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'"
                    >Accounts Edit Log</a
                  >
                </li>
                <li>
                  <a
                    class="cursor-pointer"
                    v-on:click="redirectTo('/edit-delete-log')"
                    v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'"
                    >Order/Trade Edit/Delete Log</a
                  >
                </li>
                <li>
                  <a
                    class="cursor-pointer"
                    v-on:click="redirectTo('/rejection-log')"
                    v-if="role != 'ADMIN' && role != 'SUPER_ADMIN'"
                    >Order/Trade Rejection Log</a
                  >
                </li>
              </ul>
            </li>
            <li
              style="float: left"
              class="nav-item dropdown"
              v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'">
              <a
                v-on:click="$router.push('/trade-export')"
                style="margin-top: 10px; padding: 8px 4px 0px"
                class="nav-link dropbtn">
                EXPORT TRADES
              </a>
            </li>
            <li
              style="float: left"
              class="nav-item dropdown"
              v-if="role == 'ADMIN' || role == 'SUPER_MASTER'">
              <a
                v-on:click="$router.push('/turn-over-report')"
                style="margin-top: 10px; padding: 8px 4px 0px"
                class="nav-link dropbtn">
                TURNOVER REPORT
              </a>
            </li>
            <li
              :class="view.is_system_user_show == true ? 'mm-active' : ''"
              v-if="role != 'USER' && role != 'BROKER'">
              <a
                v-on:click="showDown('is_system_user_show')"
                class="has-arrow"
                style="cursor: pointer">
                <div>SETTINGS</div>
              </a>
              <ul
                :class="
                  view.is_system_user_show == true
                    ? 'mm-collapse mm-show'
                    : 'mm-collapse'
                "
                style="
                  background: transparent !important;
                  margin-left: 35px;
                  border: 0px !important;
                ">
                <li>
                  <a
                    v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'"
                    class="cursor-pointer"
                    v-on:click="redirectTo('/setting')"
                    >Time Setting</a
                  >
                </li>
                <li>
                  <a
                    v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'"
                    class="cursor-pointer"
                    v-on:click="$router.push('/theme-setting')"
                    >Theme Setting</a
                  >
                </li>
                <li class="nav-item dropdown">
                  <a
                    v-if="
                      role == 'ADMIN' ||
                      role == 'SUPER_ADMIN' ||
                      role == 'SUPER_MASTER'
                    "
                    class="cursor-pointer"
                    v-on:click="$router.push('/master-notifications')"
                    >Notification</a
                  >
                </li>
                <li v-if="role == 'SUPER_MASTER'">
                  <a
                    class="cursor-pointer"
                    v-on:click="redirectTo('/master-quantity-setting')"
                    >Master Quantity Setting</a
                  >
                </li>
                <li v-if="role == 'SUPER_MASTER'">
                  <a
                    class="cursor-pointer"
                    v-on:click="redirectTo('/master-order-limit')"
                    >Master Order Limit</a
                  >
                </li>
                <li v-if="role == 'SUPER_MASTER'">
                  <a
                    class="cursor-pointer"
                    v-on:click="redirectTo('/master-segment-setting')"
                    >Master Segment Setting</a
                  >
                </li>
                <li>
                  <a
                    class="cursor-pointer"
                    v-on:click="redirectTo('/client-segment-setting')"
                    >Client Segment Setting</a
                  >
                </li>
                <li>
                  <a
                    class="cursor-pointer"
                    v-on:click="redirectTo('/client-order-limit')"
                    >Client Order Limit</a
                  >
                </li>
                <li>
                  <a
                    class="cursor-pointer"
                    v-on:click="redirectTo('/quantity-setting')"
                    >Client Quantity Setting</a
                  >
                </li>

                <li>
                  <a
                    v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'"
                    class="cursor-pointer"
                    v-on:click="redirectTo('/manage-master-scripts')"
                    >Manage Master Scripts</a
                  >
                </li>
                <li>
                  <a
                    v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'"
                    class="cursor-pointer"
                    v-on:click="redirectTo('/manage-scripts')"
                    >Manage Scripts</a
                  >
                </li>

                <li>
                  <a
                    class="cursor-pointer"
                    v-on:click="redirectTo('/block-script')"
                    >Block Client Script</a
                  >
                </li>
                <li v-if="role == 'SUPER_MASTER' || role == 'MANAGER'">
                  <a
                    class="cursor-pointer"
                    v-on:click="$router.push('/block-master-script')"
                    >Block Master Script</a
                  >
                </li>

                <li>
                  <a class="cursor-pointer" v-on:click="redirectTo('/block-ip')"
                    >Black List IP</a
                  >
                </li>

                <li>
                  <a
                    class="cursor-pointer"
                    v-on:click="redirectTo('/transfer-setting')"
                    >Client Transfer Setting</a
                  >
                </li>
                <li v-if="role == 'SUPER_MASTER' || role == 'MANAGER'">
                  <a
                    class="cursor-pointer"
                    v-on:click="redirectTo('/transfer-master-setting')"
                    >Master Transfer Setting</a
                  >
                </li>
              </ul>
            </li>

            <li
              :class="view.is_system_show == true ? 'mm-active' : ''"
              v-if="role != 'USER'">
              <a
                v-on:click="showDown('is_system_show')"
                class="has-arrow"
                style="cursor: pointer">
                <div>MASTER</div>
              </a>
              <ul
                :class="
                  view.is_system_show == true
                    ? 'mm-collapse mm-show'
                    : 'mm-collapse'
                "
                style="
                  background: transparent !important;
                  margin-left: 35px;
                  border: 0px !important;
                ">
                <a class="nav-item dropdown" v-if="role != 'USER'">
                  <li v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'">
                    <a
                      class="cursor-pointer"
                      v-on:click="redirectTo('/super-masters')"
                      >Super Master</a
                    >
                    <a
                      class="cursor-pointer"
                      v-on:click="redirectTo('/all-brokerages')"
                      >Brokerage</a
                    >
                    <a
                      class="cursor-pointer"
                      v-on:click="redirectTo('/new-account')"
                      >New Account</a
                    >
                  </li>
                  <li v-if="role == 'SUPER_MASTER'">
                    <a
                      class="cursor-pointer"
                      v-on:click="redirectTo('/manager')"
                      >Manager</a
                    >
                    <a
                      class="cursor-pointer"
                      v-on:click="redirectTo('/masters')"
                      >Master</a
                    >
                    <a class="cursor-pointer" v-on:click="redirectTo('/broker')"
                      >Broker</a
                    >
                    <a class="cursor-pointer" v-on:click="redirectTo('/users')"
                      >Customer</a
                    >
                    <a
                      class="cursor-pointer"
                      v-on:click="redirectTo('/all-brokerages')"
                      >Brokerage</a
                    >
                    <a
                      class="cursor-pointer"
                      v-on:click="redirectTo('/new-account')"
                      >New Account</a
                    >
                  </li>
                  <li v-if="role == 'MANAGER'">
                    <a
                      class="cursor-pointer"
                      v-on:click="redirectTo('/masters')"
                      >Master</a
                    >
                    <a class="cursor-pointer" v-on:click="redirectTo('/broker')"
                      >Broker</a
                    >
                    <a class="cursor-pointer" v-on:click="redirectTo('/users')"
                      >Customer</a
                    >
                    <a
                      class="cursor-pointer"
                      v-on:click="redirectTo('/all-brokerages')"
                      >Brokerage</a
                    >
                    <a
                      class="cursor-pointer"
                      v-on:click="redirectTo('/new-account')"
                      >New Account</a
                    >
                  </li>
                  <li v-if="role == 'MASTER'">
                    <a class="cursor-pointer" v-on:click="redirectTo('/broker')"
                      >Broker</a
                    >
                    <a class="cursor-pointer" v-on:click="redirectTo('/users')"
                      >Customer</a
                    >
                    <a
                      class="cursor-pointer"
                      v-on:click="redirectTo('/all-brokerages')"
                      >Brokerage</a
                    >
                    <a
                      class="cursor-pointer"
                      v-on:click="redirectTo('/new-account')"
                      >New Account</a
                    >
                  </li>
                  <li v-if="role == 'BROKER'">
                    <a class="cursor-pointer" v-on:click="redirectTo('/users')"
                      >Customer</a
                    >
                  </li>
                </a>
              </ul>
            </li>
            <!-- <li class="nav-item" v-if="role == 'SUPER_ADMIN' || role === 'ADMIN'">
              <a v-on:click="redirectTo('/tenents')" class="nav-link"
                >TENANTS
              </a>
            </li> -->
          </ul>
        </div>
      </div>

      <header class="top-header" style="position: absolute; right: 0; top: 10px;">
        <nav class="navbar navbar-expand">
          <div class="left-topbar d-flex align-items-center">
            <a class="toggle-btn">
              <i class="bx bx-menu"></i>
            </a>
          </div>
          <div class="right-topbar ml-auto">
            <ul class="navbar-nav">
              <li class="nav-item dropdown dropdown-user-profile">
                <a
                  class="nav-link dropdown-toggle-nocaret pr-0"
                  data-toggle="dropdown">
                  <div class="media user-box align-items-center mr-3">
                    <div
                      class="mr-4"
                      style="margin-top: -9px"
                      v-if="details.is_notification == 1">
                      <i
                        class="fa fa-volume-up"
                        v-on:click="updatepermission()"
                        aria-hidden="true"
                        style="color: white; font-size: 20px"></i>
                    </div>
                    <div
                      class="mr-4"
                      style="margin-top: -10px"
                      v-if="details.is_notification == 0">
                      <i
                        class="fa fa-volume-off"
                        v-on:click="updatepermission()"
                        aria-hidden="true"
                        style="color: white; font-size: 20px"></i>
                    </div>
                    <div
                      class="mr-4 position-relative"
                      style="margin-top: -10px; color:white;"
                      v-if="role == 'USER'">
                      <i
                        v-on:click="showNotificationDropdown()"
                        class="fa fa-bell"
                        style="color: white; font-size: 20px"
                        aria-hidden="true"></i>
                      <span class="badge badge-danger count">{{
                        details.count
                      }}</span>
                    </div>

                    <i
                      v-on:click="showDropdown()"
                      class="fa fa-user fa-2x"
                      style="color: white; margin-top: -13px; font-size: 24px"
                      aria-hidden="true"></i>
                  </div>
                </a>
                <div
                  v-click-outside="clickedOutsideV2"
                  id="notification-dropdown-id color:white;"
                  class="dropdown-menu dropdown-menu-end hide"
                  style="
                    position: absolute;
                    inset: 0px 0px auto auto;
                    margin: 0px;
                    transform: translate3d(0px, 49px, 0px);
                    margin-right: 1px;
                    height: 300px;
                    overflow-y: scroll;
                  "
                  data-popper-placement="bottom-end">
                  <h5
                    class="text-center"
                    style="margin:0px !important;font-weight:bold;">
                    Notifications
                  </h5>
                  <div class="dropdown-divider"></div>
                  <li v-for="(data, index) in notificationList" :key="index">
                    <div class="dropdown-item d-flex">
                      <div class="font-12 time col-10">
                        <h6 style="margin:0px !important;font-size:15px">
                          {{
                            data.description
                              ? $helperService.getUpperCase(data.description)
                              : ""
                          }}
                        </h6>
                        <span style="margin:0px;!important;font-size:10px">
                          {{
                            data.created_at
                              ? $helperService.getCustomDateYearnew2(
                                  data.created_at
                                )
                              : ""
                          }}
                        </span>
                      </div>
                      <div>
                        <span
                          v-if="!data.is_read"
                          class="badge badge-danger rounded-pill px-2 float-right"
                          style="
                            color: white;
                            justify-content: end;
                            display: flex;
                          "
                          >Unread</span
                        >
                        <span
                          v-else
                          class="badge badge-success rounded-pill px-2 float-right"
                          style="
                            color: white;
                            justify-content: end;
                            display: flex;
                          "
                          >Seen</span
                        >
                      </div>
                    </div>
                    <div class="dropdown-divider"></div>
                  </li>
                  <li
                    v-if="notificationList.length == 0"
                    style="
                      width: 300px;
                      height: 100px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    ">
                    <img v-if="isloader" src="/img/loader.gif" />
                    <span
                      style="color: white !important"
                      v-if="notificationList.length == 0 && !isloader"
                      >No Data Found</span
                    >
                  </li>
                </div>
                <div
                  v-click-outside="clickedOutside"
                  id="dropdown-id"
                  class="dropdown-menu dropdown-menu-end show"
                  style="
                    position: absolute;
                    inset: 0px 0px auto auto;
                    margin: 0px 13px 0px 0px;
                    transform: translate3d(0px, 49px, 0px);
                  "
                  data-popper-placement="bottom-end">
                  <h6
                    class="dropdown-header ng-binding"
                    style="font-size: 15px; font-weight: bold; color: #a77500">
                    Welcome {{ $helperService.getTitleCase(details.name) }}
                    {{ details.code ? "(" + details.code + ")" : "" }}
                    <p>
                      ({{ role.replace("_", " ") }}
                      {{
                        details.server_code ? " - " + details.server_code : ""
                      }})
                    </p>
                  </h6>

                  <div class="dropdown-divider"></div>

                  <a class="dropdown-item cursor-pointer">
                    <i class="fa fa-user mr-2" aria-hidden="true"></i>

                    <span class="align-middle">My Profile</span>
                  </a>
                  <a
                    class="dropdown-item cursor-pointer"
                    v-on:click="showChangePasswordPopup()">
                    <i class="fa fa-key mr-2" aria-hidden="true"></i>

                    <span class="align-middle">Change Password</span>
                  </a>
                  <div
                    class="dropdown-divider"
                    v-if="getSavedLogin.length > 0"></div>
                  <a
                    class="dropdown-item cursor-pointer align-middle"
                    v-if="getSavedLogin.length > 0">
                    <i class="fa fa-key mr-2" aria-hidden="true"></i>

                    <span class="align-middle">Saved Logins : </span>
                  </a>
                  <div
                    v-for="(data, index) in getSavedLogin"
                    :key="index"
                    class="">
                    <div
                      class="col-12 row cursor-pointer mt-1 p-0 m-0"
                      style="
                        background-color: #e3e3ea;
                        border-radius: 4px 4px 4px 4px;
                        height: 40px;
                      ">
                      <div
                        class="col-8"
                        style="
                          justify-content: start;
                          display: inline-flex;
                          margin-top: 14px;
                          font-size: 12px;
                        ">
                        <i
                          class="fa fa-user mr-2"
                          style="margin-top: 3px !important"
                          aria-hidden="true"></i>
                        {{ data.server_code }} - {{ data.code }}
                      </div>
                      <div
                        class="col-4"
                        style="
                          justify-content: end;
                          display: inline-flex;
                          margin-top: 14px;
                        ">
                        <i
                          class="fa fa-sign-in ml-3"
                          title="login"
                          aria-hidden="true"
                          v-on:click="login(index)"></i>
                        <i
                          class="fa fa-trash-o ml-3"
                          aria-hidden="true"
                          style="color: red"
                          title="Remove"
                          v-on:click="remove(index)"></i>
                      </div>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item cursor-pointer" v-on:click="logout()"
                    ><i class="fa fa-sign-out mr-2" aria-hidden="true"></i>

                    <span class="align-middle">Logout</span></a
                  >
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </div>
    <TextModal ref="textModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Change Password</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.textModal.closeModal">
          <span aria-hidden="true" style="color: white;">&times;</span>
        </button>
      </div>
      <Form ref="changePasswordForm" @submit="changePassword()">
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Old Password</label>
            <span class="text-danger">*</span>
            <Field
              type="password"
              name="_password"
              class="form-control"
              placeholder="Old Password"
              v-model="old_password"
              rules="required:old password"
              :validateOnInput="true" />
            <ErrorMessage name="_password" class="text-danger" />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>New Password</label>
            <span class="text-danger">*</span>
            <Field
              type="password"
              name="name_password"
              class="form-control"
              placeholder="New Password"
              v-model="new_password"
              rules="required:new password|minLength:6"
              :validateOnInput="true" />
            <ErrorMessage name="name_password" class="text-danger" />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Confirm Password</label>
            <span class="text-danger">*</span>
            <Field
              type="password"
              placeholder="Confirm Password"
              name="name_ccpassword"
              class="form-control"
              rules="required:confirm password|confirmed:@name_password"
              :validateOnInput="true" />
            <ErrorMessage name="name_ccpassword" class="text-danger" />
          </div>
        </div>
        <div class="pb-0 text-center">
          <button class="save-btn" id="change-password-button">Save</button>
        </div>
      </Form>
    </TextModal>
    <ErrorComponent @retry="getLoginUserDetail" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal.vue";
import swal from "sweetalert2";
import ErrorComponent from "@/components/ErrorComponent";

export default {
  name: "Menu",
  components: {
    TextModal,
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
  },
  data() {
    return {
      pageName: "",
      role: this.$store.getters.getUser?.role,
      view: {
        is_system_user_show: false,
        is_system_show: false,
        is_system: false,
        is_system_report: false,
      },
      message: "",
      password: "",
      confirm_password: "",
      details: {
        count: 0,
      },
      notificationList: [],
      isShow: false,
      isPlayAudio: false,
      time: 0,
      eventDisable: false,
      NotieventDisable: false,
      keyword: "",
      isCall: false,
      client: undefined,
      count: 0,
      text: "",
      isloader: false,
      getSavedLogin: [],
    };
  },
  watch: {
    "$route.fullPath"(newValue) {
      var pathname = newValue.replace("/", "");
      this.pageName = pathname;
    },
  },
  mounted() {
    this.getLoginUsers();
    this.pageName = this.$route.fullPath.replace("/", "");
    this.clickedOutside();
    this.clickedOutsideV2();
    this.getDetails();
    this.$helperService.showMenu("false");
  },
  methods: {
    getLoginUsers() {
      this.getSavedLogin = JSON.parse(
        localStorage.getItem("saved_logins") || "[]"
      );
      for (var i in this.getSavedLogin) {
        if (localStorage.getItem("code") == this.getSavedLogin[i].code) {
          this.getSavedLogin.splice(i, 1);
        }
      }
    },
    remove(index) {
      for (var i in this.getSavedLogin) {
        if (i == index) {
          this.getSavedLogin.splice(i, 1);
        }
      }
      localStorage.setItem("saved_logins", JSON.stringify(this.getSavedLogin));
    },
    login(index) {
      this.$refs.errorComponent.updateFormLoader(true);
      for (var i in this.getSavedLogin) {
        if (i == index) {
          var obj = this.getSavedLogin[i];
        }
      }
      this.$api
        .postAPI({
          _action: "/ouath-token",
          _body: obj,
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("token", res.access_token);
            this.getLoginUserDetail2();
          }
        })
        .catch(() => {});
    },
    getLoginUserDetail2() {
      this.$api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("role", res.role);
            localStorage.setItem("userid", res.id);
            localStorage.setItem("code", res.code);
            localStorage.setItem("client_ids", JSON.stringify(res.client_ids));
            if (res.role == "ADMIN") {
              this.$router.push("/super-masters");
            } else if (res.role == "SUPER_ADMIN") {
              this.$router.push("/tenents");
            } else {
              localStorage.setItem("is_edit", res.is_edit);
              this.$router.push("/dashboard");
            }
            this.$refs.errorComponent.updateFormLoader(false);
            window.location.reload();
          }
        })
        .catch(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          localStorage.removeItem("client_ids");
        });
    },
    toggleMenu() {
      if (document.getElementById("sidebar-div").style.width == "300px") {
        this.$helperService.showMenu("false");
      } else {
        this.$helperService.showMenu("true");
      }
    },
    redirectTo(url) {
      this.$helperService.showMenu("false");
      this.$router.push(url);
    },
    showDown(type) {
      if (type == "is_system_user_show") {
        this.view = {
          is_system_user_show: !this.view.is_system_user_show,
          is_system_show: false,
          is_system: false,
          is_system_report: false,
        };
      } else if (type == "is_system") {
        this.view = {
          is_system: !this.view.is_system,
          is_system_user_show: false,
          is_system_show: false,
          is_system_report: false,
        };
      } else if (type == "is_system_show") {
        this.view = {
          is_system_show: !this.view.is_system_show,
          is_system_user_show: false,
          is_system: false,
          is_system_report: false,
        };
      } else if (type == "is_system_report") {
        this.view = {
          is_system_report: !this.view.is_system_report,
          is_system_user_show: false,
          is_system: false,
          is_system_show: false,
        };
      }
    },

    updatepermission() {
      var text = "";
      if (this.details.is_notification == 1) {
        text = "Are you sure you want to slient trade execute sound";
      }
      if (this.details.is_notification == 0) {
        text = "Are you sure you want to on trade execute sound";
      }
      swal
        .fire({
          title: "Are you sure?",
          text: text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .putAPI({
                _action: "/update-edit-notification",
              })
              .then((res) => {
                if (res) {
                  this.getLoginUserDetail();
                }
              })
              .catch(() => {});
          } else {
            this.getLoginUserDetail(0);
          }
        });
    },
    getNotificationList() {
      this.isloader = true;
      this.$api
        .getAPI({
          _action: "/message-list",
        })
        .then((res) => {
          this.notificationList = res;
          this.isloader = false;
        })
        .catch(() => {
          this.isloader = false;
        });
    },
    getDetails() {
      this.$api
        .getAPI({
          _action: "/settings",
        })
        .then((res) => {
          this.message = res.header_message;
        })
        .catch(() => {});
    },
    clickedOutside() {
      if (!this.eventDisable) {
        var dropdownId = document.getElementById("dropdown-id");
        if (dropdownId) {
          dropdownId.classList.remove("show");
        }
      } else {
        this.eventDisable = false;
      }
    },
    clickedOutsideV2() {
      if (!this.NotieventDisable) {
        var dropdownId = document.getElementById("notification-dropdown-id");
        if (dropdownId) {
          dropdownId.classList.remove("show");
        }
      } else {
        this.NotieventDisable = false;
      }
    },
    showDropdown() {
      this.eventDisable = true;
      var dropdownId = document.getElementById("dropdown-id");
      if (dropdownId) {
        if (dropdownId.className.includes("show")) {
          dropdownId.classList.remove("show");
        } else {
          dropdownId.classList.add("show");
        }
      }
    },
    showNotificationDropdown() {
      this.getNotificationList();
      this.getLoginUserDetail();
      this.NotieventDisable = true;
      var dropdownId = document.getElementById("notification-dropdown-id");
      if (dropdownId) {
        if (dropdownId.className.includes("show")) {
          dropdownId.classList.remove("show");
        } else {
          dropdownId.classList.add("show");
        }
      }
    },
    logout() {
      swal
        .fire({
          title: "Are you sure?",
          text: "You will be logged out of the portal",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Logout",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // localStorage.clear();
            localStorage.removeItem("token");
            localStorage.removeItem("role");
            localStorage.removeItem("client_ids");
            this.$router.push("/login");
          }
        });
    },
    getLoginUserDetail() {
      this.$api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          this.details = res;
          this.$store.commit("setUser", res);
          localStorage.setItem("role", res.role);
          localStorage.setItem("is_edit", res.is_edit);
        })
        .catch(() => {});
    },
    showChangePasswordPopup() {
      this.clickedOutside();
      this.clickedOutsideV2();
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    changePassword() {
      var obj = {};
      obj.old_password = this.old_password;
      obj.new_password = this.new_password;
      obj.id = this.details.id;
      this.$api
        .putAPI({
          _action: "/reset/password",
          _body: obj,
          _buttonId: "change-password-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
  },
};
</script>
